import { useEffect, useState } from "react";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { useAuthState } from "../../../../MainComponents/GlobalContext";
import { toast } from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const Whatsapp = () => {
  const AuthState = useAuthState();
  const [stage, setStage] = useState("");
  const [type, setType] = useState("");
  const [attendance, setAttendance] = useState("");
  const [center, setCenter] = useState("");
  const [message, setMesaage] = useState("");
  const [messageTo, setMessageTo] = useState("");
  const [url, setUrl] = useState("");
  const [caption, setCaption] = useState("");
  const [sendMessage, setSendMessage] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  let fullMessage = {
    message: message,
    stage: stage,
    send_to: messageTo,
    attendance_location: attendance,
    type,
    url,
    caption,
  };

  if (attendance === "0") {
    fullMessage = {
      ...fullMessage,
      group_id: center,
    };
  }

  const [allCenters] = useAxios(
    process.env.REACT_APP_ATTENDANCE_LOCATION_API,
    "GET",
    "false"
  );

  const [
    sendMessageToWhatsappSucsses,
    sendMessageErrors,
    sendMessageToWhatsappLoading,
  ] = useAxios(
    process.env.REACT_APP_WHATSAPP_SENDING_MESSAGE_API,
    "POST",
    sendMessage.flag,
    sendMessage.dependency,
    sendMessage.data
  );

  const filterdCenters = allCenters?.groups?.filter(
    (center) => center.stage === Number(stage)
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    setSendMessage({
      flag: "sendMessageToWhatsapp",
      dependency: !sendMessage.dependency,
      data: fullMessage,
    });
  };

  useEffect(() => {
    if (sendMessageToWhatsappSucsses) {
      toast.success(sendMessageToWhatsappSucsses?.message);
    }
  }, [sendMessageToWhatsappSucsses]);

  useEffect(() => {
    if (sendMessageErrors) {
      toast.error(sendMessageErrors?.response?.data.message);
    }
  }, [sendMessageErrors]);
  return (
    <>
      <HelmetTags title="WhatsApp | Ms Amira Shaltout"></HelmetTags>
      <div className="h-full w-full overflow-y-auto px-20 py-20 sm:px-10 ">
        <h2 className="w-full  text-4xl font-bold md:text-center mb-12">
          WhatsApp
        </h2>
        <form
          onSubmit={handleSubmit}
          className="flex w-3/4 md:w-full mx-auto flex-col items-start  justify-center gap-6 rounded-3xl bg-white p-10 shadow-3xl"
        >
          <h2 className="mb-8 w-full text-center">
            Please enter the text you want to send
          </h2>
          <textarea
            name="message"
            value={message}
            onChange={(e) => setMesaage(e.target.value)}
            placeholder="Enter message"
            className="p-4 w-full h-[120px]"
          />

          <div className="w-full flex justify-center items-center gap-4 md:flex-col">
            <div className="w-1/2 md:w-full">
              <label className="w-full truncate  font-semibold" htmlFor="url">
                Link
              </label>
              <input
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                disabled={message !== ""}
                className="signin-inputs w-full pl-4 disabled:opacity-50 disabled:cursor-not-allowed"
                type="url"
                name="url"
                id="url"
                placeholder="https://example.com"
              />
            </div>
            <div className="w-1/2 md:w-full">
              <label
                className="w-full truncate  font-semibold"
                htmlFor="caption"
              >
                Caption
              </label>
              <input
                value={caption}
                onChange={(e) => setCaption(e.target.value)}
                disabled={message !== ""}
                className="signin-inputs w-full pl-4 disabled:opacity-50 disabled:cursor-not-allowed"
                type="caption"
                name="caption"
                id="caption"
                placeholder="Caption"
              />
            </div>
          </div>

          <div className="flex w-full items-start justify-end md:justify-evenly gap-10 md:gap-6 flex-col mt-5">
            <div className="w-full flex justify-center items-center gap-4 md:flex-col">
              <div className="w-1/2  md:w-full">
                <label
                  className="w-full truncate  font-semibold"
                  htmlFor="attendance"
                >
                  Attendance
                </label>
                <select
                  className=""
                  value={attendance}
                  onChange={(e) => setAttendance(e.target.value)}
                  name="attendance"
                  id="attendance"
                >
                  <option hidden disabled value="">
                    Attendance
                  </option>
                  <option value="1">Online</option>
                  <option value="0">Center</option>
                </select>
              </div>
              <div
                className={`w-1/2 md:w-full order-4 ${
                  attendance === "0" ? "visible" : "invisible"
                }`}
              >
                <label
                  className="w-full truncate  font-semibold"
                  htmlFor="center"
                >
                  Center
                </label>
                <select
                  className=""
                  value={center}
                  onChange={(e) => setCenter(e.target.value)}
                  name="center"
                  id="center"
                >
                  {filterdCenters?.map((center) => (
                    <option
                      key={center.n}
                      value={center.key}
                      onChange={(e) => setCenter(e.target.value)}
                    >
                      {center.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="w-full flex  gap-4 md:flex-col">
              <div className="flex w-1/2 flex-col  items-center md:order-2 md:w-full">
                <label
                  className="text-start flex items-center gap-2"
                  htmlFor="Type"
                >
                  Type
                </label>
                <div className="flex w-full gap-20 pb-1 sm:flex-col-reverse">
                  {/** ig  */}
                  <div className=" flex  items-center justify-center gap-2 ">
                    <input
                      className="cursor-pointer"
                      type="radio"
                      id="ig"
                      value="0"
                      name="type"
                      onChange={(e) => setType(e.target.value)}
                    />
                    <label className="cursor-pointer text-start" htmlFor="ig">
                      IG
                    </label>
                  </div>

                  {/** national */}
                  <div className=" flex  items-center justify-center gap-2 ">
                    <input
                      className="cursor-pointer"
                      type="radio"
                      id="national"
                      value="1"
                      name="type"
                      onChange={(e) => setType(e.target.value)}
                    />
                    <label
                      className="cursor-pointer text-start"
                      htmlFor="national"
                    >
                      National
                    </label>
                  </div>
                </div>
              </div>
              {type && (
                <div className="w-1/2 md:w-full">
                  <label
                    className="w-full truncate  font-semibold"
                    htmlFor="stage"
                  >
                    Class
                  </label>
                  {type === "0" ? (
                    <select name="stage" id="stage" value={stage}>
                      <option value="" disabled selected>
                        Select a stage
                      </option>

                      <option value="9">Nine Year</option>
                      <option value="10">Ten Year</option>
                    </select>
                  ) : (
                    <>
                      <select
                        name="stage"
                        id="stage"
                        onChange={(e) => setStage(e.target.value)}
                      >
                        <option value="" disabled selected>
                          Select a stage
                        </option>

                        <option value="22">Second preparatory</option>
                        <option value="33">Third preparatory </option>
                        <option value="1">First Secondary</option>
                        <option value="2">Second Secondary</option>
                        <option value="3">Third Secondary</option>
                      </select>
                    </>
                  )}
                </div>
              )}
            </div>

            <div className="w-full flex  gap-4 md:flex-col">
              <div className="w-1/2 md:w-full">
                <label
                  className="w-full truncate  font-semibold"
                  htmlFor="message"
                >
                  Message to
                </label>
                <select
                  className=""
                  value={messageTo}
                  onChange={(e) => setMessageTo(e.target.value)}
                  name="message"
                  id="message"
                >
                  <option hidden disabled value="">
                    Message To
                  </option>
                  <option value="1">Student</option>
                  <option value="2">Parent 1</option>
                  <option value="3">Parent 2</option>
                </select>
              </div>
            </div>
          </div>
          <div className="w-full">
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("whatsapp_send")
              )) && (
              <button
                type="submit"
                disabled={sendMessageToWhatsappLoading}
                className=" submit mt-10"
              >
                {sendMessageToWhatsappLoading ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                  "Send message"
                )}
              </button>
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default Whatsapp;
