import {
  faFacebookF,
  faInstagram,
  faTelegram,
  faTiktok,
  faWhatsapp,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import logolight from "../../src/assets/header/logo2.png";
function Footer() {
  return (
    <footer className="relative bg-secondary pt-8 pb-6">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap text-left lg:text-left">
          <div className="w-full lg:w-6/12 px-4 flex justify-between items-center flex-wrap">
            <div className="flex gap-5 flex-wrap items-center">
              <img src={logolight} alt="logo" className="h-60" />
            </div>

            <ul className="mt-6 flex gap-5 flex-wrap lg:mb-0 mb-6">
              <li className="group flex h-20 w-20 cursor-pointer items-center justify-center rounded-full border-2  bg-yellow border-black bg-yellow-500 duration-300 hover:scale-110 hover:bg-white ">
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="text-2xl text-light/80  "
                  href="https://www.facebook.com/Ms.Amira87?mibextid=ZbWKwL"
                >
                  <FontAwesomeIcon
                    className="text-5xl text-black duration-300 group-hover:scale-110 group-hover:text-black"
                    icon={faFacebookF}
                  />
                </a>
              </li>
              <li className="group flex h-20 w-20 cursor-pointer items-center justify-center rounded-full border-2  border-black bg-yellow duration-300 hover:scale-110 hover:bg-white ">
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="text-2xl text-light/80  "
                  href="https://www.tiktok.com/@themathematician.as?_t=8oK7VzRDGza&_r=1"
                >
                  <FontAwesomeIcon
                    className="text-5xl text-black duration-300 group-hover:scale-110 group-hover:text-black"
                    icon={faTiktok}
                  />
                </a>
              </li>
              <li className="group flex h-20 w-20 cursor-pointer items-center justify-center rounded-full border-2 border-black bg-yellow duration-300 hover:scale-110 hover:bg-white">
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="text-2xl text-light/80  "
                  href="https://api.whatsapp.com/send?phone=+201288600001"
                >
                  <FontAwesomeIcon
                    className="text-5xl  text-black duration-300 group-hover:scale-110 group-hover:text-green-500"
                    icon={faWhatsapp}
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <hr className="my-6 border-white" />
        <div className="flex flex-wrap items-center md:justify-between justify-center">
          <div className="w-full md:w-4/12 px-4 mx-auto text-center">
            <div className="text-sm text-white font-semibold py-1">
              Copyright © <span id="get-current-year">2024</span>
              <Link
                to="https://www.natlix.com"
                className="text-yellow hover:text-gray-800"
                target="_blank"
              >
                {" "}
                by Natlix
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
