import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useParams } from "react-router-dom";
import { useAuthState } from "../../../../MainComponents/GlobalContext";
import useTable from "../../../../MainComponents/Hooks/useTable";
import HelmetTags from "../../../../MainComponents/HelmetTags";

function AdminMaterials() {
  const AuthState = useAuthState();
  const { lectureID } = useParams();
  const table = useTable("materials", "", "", "", "", lectureID);

  return (
    <section
      className={`  flex  h-auto   w-full flex-col  items-start px-20 py-20 dark:bg-dark dark:text-light`}
    >
      <HelmetTags title="Materials | Ms Amira Shaltout"></HelmetTags>
      {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("lecture_addmatrial")
        )) && (
        <Link
          to={`/admin/lectures/${lectureID}/add-material`}
          className="mb-20 ml-4 rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-light   hover:text-secondary active:scale-90 "
        >
          <FontAwesomeIcon className="mr-4" icon={faPlus} />
          Add File
        </Link>
      )}
      {/**
       * //!---------Table-----
       */}
      <div className="h-fit  w-full">{table}</div>
    </section>
  );
}

export default AdminMaterials;
