import { faClock } from "@fortawesome/free-regular-svg-icons";
import {
  faAddressCard,
  faBuildingUser,
  faChalkboardUser,
  faMobileScreenButton,
  faSchoolFlag,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popconfirm } from "antd";
import { useAuthState } from "../../../../MainComponents/GlobalContext";
import { useState } from "react";

function RequestsCard({ student, handleAcceptReq, handleDeleteReq }) {
  const AuthState = useAuthState();

  const [level, setLevel] = useState("beginner");

  return (
    <>
      <div
        key={student.key}
        //!single student
        className="border-2 border-secondary mx-auto mb-20 flex flex-row-reverse h-fit w-5/6 items-center justify-between gap-12 rounded-xl  p-6 lg:w-full amd:h-auto amd:flex-col-reverse amd:gap-16"
      >
        <div
          //!std-left
          className="std-details amd: flex flex-col h-full w-3/5 items-center justify-evenly  gap-5 rounded-3xl amd:w-full sm:flex-col-reverse sm:gap-14 sm:p-10"
        >
          <div className="std-info flex h-auto w-full flex-col justify-start gap-8 ">
            <div className="flex justify-start">
              <div className="w-8 min-w-[32px]  text-3xl ">
                <FontAwesomeIcon className="" icon={faMobileScreenButton} />
              </div>
              <h4 className=" text-[17px] text-black  font-semibold ">
                Phone:{" "}
                <span className="text-[15px] opacity-80">{student.mobile}</span>
              </h4>
            </div>
            <div className="flex justify-start">
              <div className="w-8 min-w-[32px]  text-3xl ">
                <FontAwesomeIcon className="" icon={faMobileScreenButton} />
              </div>
              <h4 className=" text-[17px] text-black  font-semibold ">
                Parent's phone:
                <span className="text-[15px] opacity-80 xs:text-center">
                  {student.parent_mobile}
                </span>
              </h4>
            </div>

            <div className="flex justify-start">
              <div className="w-8 min-w-[32px] text-3xl">
                <FontAwesomeIcon
                  className=""
                  icon={
                    student.is_online === "Center"
                      ? faBuildingUser
                      : faChalkboardUser
                  }
                />
              </div>
              <div className=" text-[17px] text-black  font-semibold ">
                <h5 className="inline-block">Place: </h5>
                <span className="text-[15px] opacity-80">
                  {student.is_online}
                </span>
              </div>
            </div>
            {student.section && (
              <div className="flex justify-start">
                <div className="w-8 min-w-[32px]   text-3xl ">
                  <FontAwesomeIcon className="" icon={faAddressCard} />
                </div>
                <div className=" text-[17px] text-black  font-semibold ">
                  <h5 className="inline-block">Courses: </h5>
                  <span className="text-[15px] opacity-80">
                    {student.section}
                  </span>
                </div>
              </div>
            )}
            <div className="flex justify-start">
              <div className="w-8 min-w-[32px]   text-3xl ">
                <FontAwesomeIcon className="" icon={faSchoolFlag} />
              </div>
              <div className=" text-[17px] text-black  font-semibold ">
                <h5 className="inline-block">School: </h5>
                <span className="text-[15px] opacity-80">{student.school}</span>
              </div>
            </div>

            <div className="flex justify-start">
              <div className="w-8 min-w-[32px]  text-3xl ">
                <FontAwesomeIcon className="" icon={faClock} />
              </div>
              <h4 className=" text-[17px] text-black  font-semibold ">
                Release Date:
                <span className="text-[15px] opacity-80">
                  {student.created_at}
                </span>
              </h4>
            </div>

            <div className="flex justify-start">
              <select
                name="section"
                id="section"
                placeholder="Select Section"
                value={level}
                onChange={(e) => setLevel(e.target.value)}
              >
                <option value="beginner">Beginner</option>
                <option value="advanced">Advanced</option>
              </select>
            </div>
          </div>
          <div className="w-full btns flex items-center  gap-6 sm:flex-row mt-10 ">
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("request_accept")
              )) && (
              <button
                onClick={() => handleAcceptReq(student.key, level)}
                className="approve-btn w-1/2   rounded-xl border-2 border-blue-500 bg-blue-500 py-6 text-center text-2xl text-white text-semibold transition-all duration-300 hover:scale-105 hover:bg-transparent hover:text-blue-500  active:scale-90"
              >
                Accept
              </button>
            )}
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("request_delete")
              )) && (
              <Popconfirm
                okText="Confirm"
                okType="red"
                cancelText="Cancel"
                color="#b1e8f4"
                //okButtonProps={{ loading: loading }}
                onConfirm={() => handleDeleteReq(student.key)}
                title="Do you really want to delete the student's request?"
              >
                <button className="approve-btn  w-1/2   rounded-xl border-2 border-accent bg-accent py-6 text-center text-2xl text-light transition-all duration-300 hover:scale-105 hover:bg-transparent  hover:text-accent active:scale-90">
                  Delete
                </button>
              </Popconfirm>
            )}
          </div>
        </div>
        <div
          //!std-right
          className="std-img flex h-full w-2/5 flex-col items-center justify-center gap-9 amd:w-full "
        >
          <div className="std-img bg-green h-96 w-96 overflow-hidden rounded-xl p-4">
            <div className="h-full w-full rounded-xl object-cover object-top p-4">
              <img
                className="h-full w-full rounded-xl object-cover object-top "
                src={student.profile_pic}
                alt={student.name}
              />
            </div>
          </div>
          <div className="name-stage flex items-center justify-center gap-4">
            <h3 className=" border-r-2 border-r-secondary pr-4 text-center text-3xl font-semibold">
              {student.name}
            </h3>
            <h4 className=" min-w-fit text-center text-3xl font-semibold opacity-80 text-yellow">
              Class {student.grade}
            </h4>
          </div>
        </div>
      </div>
    </>
  );
}

export default RequestsCard;
