import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-hot-toast";
import { useEffect, useRef, useState } from "react";
import ModalImage from "react-modal-image";

const Question = ({ state, dispatch, homework, exam, isParagraphe }) => {
  const { ID } = useParams();
  const essayAnswerRef = useRef();
  const fileInputRef = useRef();
  const [preview, setPreview] = useState(null);

  const checkApi = homework
    ? process.env.REACT_APP_STUDENT_HOMEWORK_UPDATE_API
    : exam
      ? process.env.REACT_APP_STUDENT_TRIAL_EXAM_UPDATE_API
      : process.env.REACT_APP_STUDENT_QUIZZ_UPDATE_API;

  const handleSelectAnswer = (answer, questionId, n) => {
    dispatch({
      type: "setAnsweredQuestions",
      payload: {
        id: questionId,
        order: answer,
        is_essay: 0,
        n,
        // file: fileInputRef.current.files[0],
      },
    });

    async function fetchData() {
      try {
        let res = await axios.post(checkApi, {
          id: questionId,
          order: answer,
          lecture_id: ID,
          is_essay: 0,
          n: n,
        });
      } catch (error) {
        console.error("Error:", error);
        toast.error(error.response.data.message);
      }
    }

    fetchData();

    dispatch({
      type: "setIsSelectedAnswer",
      payload: { questionId, answer, n },
    });
  };

  const handleOnBlurEssayAnswer = (questionId, n) => {
    dispatch({
      type: "setAnsweredQuestions",
      payload: {
        id: questionId,
        order: essayAnswerRef.current.value,
        is_essay: 1,
        n,
      },
    });

    async function fetchData() {
      try {
        let res = await axios.post(checkApi, {
          id: questionId,
          order: essayAnswerRef.current.value,
          lecture_id: ID,
          is_essay: 1,
          n: n,
        });
      } catch (error) {
        console.error("Error:", error);
        toast.error(error.response.data.message);
      }
    }

    fetchData();
  };

  const handleFileChange = async () => {
    const file = fileInputRef.current.files[0];
    if (!file) return;

    // Set image preview
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview(reader.result);
    };
    reader.readAsDataURL(file);

    const questionId = state.questions[state.currentQuestionIndex].id;
    const order = essayAnswerRef.current.value;

    const formData = new FormData();
    formData.append("id", questionId);
    formData.append("file", file);
    formData.append("lecture_id", ID);
    formData.append("is_essay", 1);
    formData.append("order", order);

    try {
      await axios.post(checkApi, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch({
        type: "setAnsweredQuestions",
        payload: {
          id: questionId,
          order,
          is_essay: 1, // Assuming 1 indicates an essay
          file,
        },
      });
      toast.success("File uploaded successfully");
    } catch (error) {
      console.error("Error:", error);
      toast.error(
        error.response?.data?.message ||
          "An error occurred while uploading the file"
      );
    }
  };

  useEffect(() => {
    const currentAnswerd = state.answeredQuestions.filter(
      (question) =>
        question?.id === state?.questions[state.currentQuestionIndex]?.id
    )[0];
    // if not essay question set textarea by the essay question value
    if (!state?.questions[state.currentQuestionIndex]?.answers) {
      if (
        state?.questions[state.currentQuestionIndex]?.answer ||
        currentAnswerd?.order
      ) {
        /* essayAnswerRef.current.value = currentAnswerd?.order
          ? currentAnswerd?.order
          : state?.questions[state.currentQuestionIndex]?.answer; */
      } else {
        /*  essayAnswerRef.current.value = ""; */
      }
    }
  }, [state.answeredQuestions, state.currentQuestionIndex, state?.questions]);

  return (
    <div
      className={`container flex-1 ${
        isParagraphe && "h-[90vh] w-full overflow-y-auto py-10 sm:px-10 px-3"
      }`}
    >
      {isParagraphe ? (
        <>
          {state.questions
            .filter(
              (question) => question.n_section === state.currentSectionIndex // Filter questions by current section
            )
            .slice(0, 4) // Limit to 4 questions per section
            .map((question, index) => (
              <div
                key={index}
                className="paragraphe singleQuestionWrapper rounded-2xl bg-white mb-4"
              >
                <div className="optionsQuestionWrapper current-question flex-col !gap-0">
                  <div className="categoryQuestionWrapper">
                    {`${question.n}.`}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: question?.question,
                      }}
                    />
                  </div>
                </div>
                <div className="categoryAnswersWrapper current-question-answers">
                  {question?.answers.map((answer, i) => (
                    <div
                      key={i}
                      className={`categorySingleAnswer answer1Wrapper ${
                        question.selected_answer === answer.order && "selected"
                      }`}
                      onClick={() =>
                        handleSelectAnswer(
                          answer.order,
                          question.id,
                          question.n
                        )
                      }
                    >
                      <span className="number">{answer.order} </span>
                      <p dangerouslySetInnerHTML={{ __html: answer.answer }} />
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </>
      ) : (
        <>
          <div className="current-question flex-row">
            {`${state.currentSectionIndex}.`}
            <div
              dangerouslySetInnerHTML={{
                __html:
                  state.questions[state.currentSectionIndex - 1]?.question,
              }}
            />
          </div>

          {/* <div className="current-question-degree">
            <p>
              {" "}
              {state.questions[state.currentSectionIndex - 1]?.degree} degree
            </p>
          </div> */}

          <div className="current-question-answers">
            {state.questions[state.currentSectionIndex - 1]?.answers.map(
              (answer, i) => (
                <div
                  key={i}
                  className={`${
                    state.questions[state.currentSectionIndex - 1]
                      .selected_answer === answer.order && "selected"
                  }`}
                  onClick={() =>
                    handleSelectAnswer(
                      answer.order,
                      state.questions[state.currentSectionIndex - 1].id,
                      state.questions[state.currentSectionIndex - 1].n
                    )
                  }
                >
                  <span>{answer.order} </span>
                  <p dangerouslySetInnerHTML={{ __html: answer.answer }} />
                </div>
              )
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Question;
