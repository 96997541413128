
function Loader() {
  return (
    <div className="fixed top-0 bottom-0 right-0 left-0 w-full h-screen flex items-center justify-center">  
        <div className="loader2"></div>
        <div className="loader3"></div>
    </div>

  );
}

export default Loader;
