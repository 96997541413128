import { useEffect, useReducer, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Loader } from "../../../../MainComponents";
import { duration } from "moment/moment";
function AddEditChapter({ edit, type }) {
  const [values, setValues] = useState(null);
  const [reFetch, setReFtech] = useState(false);
  const [visibletoall, setVisibletoall] = useState(0);
  const { chapterID } = useParams();
  const navigate = useNavigate();
  const datestart = useRef();
  const dateend = useRef();
  const [data, setData] = useState([]);
  const [sectionValue, setSectionValue] = useState(null);
  const [getHomeWork, errorHomeWork, LoadingHomework] = useAxios(
    "https://api.amirashaltout.com/api/admin/homework/add",
    "GET",
    "GET",
    reFetch
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    watch,
    setValue,
  } = useForm({
    mode: "onTouched",
    defaultValues: {
      name: "",
      section: "",
      next_homework: "",
      start_deadline: "",
      end_deadline: "",
      visibility: false,
      img: "",
      edu_type: "",
      stage: "",
      duration: "",
      model_answer_visibilty: 1,
      description: "",
    },
  });

  function reducer(state, action) {
    switch (action.type) {
      case "setErrors": {
        return {
          ...state,
          errors: action.payload,
        };
      }

      case "setSubmitAddEditChapter": {
        return {
          ...state,
          submitAddEditChapter: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    submitAddEditChapter: {
      flag: "",
      dependency: false,
      data: {},
    },
  });

  let api = edit
    ? type === "homework" &&
      "https://api.amirashaltout.com/api/admin/homework/edit"
    : edit
      ? process.env.REACT_APP_ADMIN_EDIT_COURSE_API
      : type === "homework"
        ? process.env.REACT_APP_CREATE_HOMEWORK_API
        : process.env.REACT_APP_ADMIN_ADD_COURSE_API;
  //!--------- add edit Chapter -------

  const [ChapterAddEditSuccess, ChapterAddEditErrors, ChapterAddEditLoading] =
    useAxios(
      api,
      "POST",
      state.submitAddEditChapter.flag,
      state.submitAddEditChapter.dependency,
      state.submitAddEditChapter.data,
      true
    );

  //!--------- get the Chapter info for editing -------
  let getapi =
    type === "homework"
      ? "https://api.amirashaltout.com/api/admin/homework/"
      : process.env.REACT_APP_ADMIN_COURSE_INFO_API;
  const [ChapterInfo, ChapterInfoErrors, ChapterInfoLoading] = useAxios(
    `${getapi}${chapterID}`,
    "GET",
    chapterID,
    chapterID
  );

  const watchsection = watch("section");
  useEffect(() => {
    if (ChapterInfo) {
      let temp = ChapterInfo.data;
      delete temp.img;
      setSectionValue(ChapterInfo?.data?.section);
      setValues({
        ...temp,
        visibility: temp.visibility === 1 ? true : false,
        edu_type: String(temp.edu_type),
        stage: String(temp.stage),
      });
    }
  }, [ChapterInfo]);

  useEffect(() => {
    //!---add actions ----

    if (ChapterAddEditSuccess) {
      navigate(-1);
      reset();
    }
    //! make the dependency false again to listen to the next try to submit
  }, [ChapterAddEditSuccess]);

  const watchVisible = watch("visible_to_all");
  const watchType = watch("edu_type");
  const watchLevel = watch("stage");

  useEffect(() => {
    console.log(watchsection);
    const filteredPractise = getHomeWork?.data?.filter((lecture) => {
      if (watchsection === "all" || !watchsection) return true;

      return lecture.section === watchsection; // Filter by stage
    });
    setData(filteredPractise);
  }, [watchsection, getHomeWork]);
  useEffect(() => {
    if (ChapterInfo?.data) {
      reset({
        name: ChapterInfo.data.name || "",
        section: ChapterInfo.data.section || "",
        next_homework: ChapterInfo?.data?.next_homework || "",
        start_deadline: ChapterInfo.data.start_deadline || "",
        end_deadline: ChapterInfo.data.end_deadline || "",
        visibility: ChapterInfo.data.visibility || false,
        img: ChapterInfo.data.img || "",
        edu_type: ChapterInfo.data.edu_type || "",
        stage: ChapterInfo.data.stage || "",
        duration: ChapterInfo.data.duration || "",
        model_answer_visibilty: ChapterInfo.data.model_answer_visibilty || 1,
        description: ChapterInfo.data.description || "",
      });
    }
  }, [ChapterInfo, reset]);
  const handleSectionChange = (e) => {
    const value = e.target.value === "all" ? null : e.target.value; // Map "all" to null
    setValue("section", value); // Set the correct value for the section field
  };
  const onSubmit = (data) => {
    const finalData = chapterID
      ? {
          ...data,
          model_answer_visibilty: 1,
          next_homework: data.next_homework === "" ? null : data.next_homework,
          section: watchsection === "all" ? null : watchsection,
          id: chapterID,
          visibility: +data?.visibility,
          img: data?.img[0] || null,
        }
      : type === "homework"
        ? {
            ...data,
            /*  start_deadline: startdateformat,
            end_deadline: startdateformat, */
            next_homework:
              data.next_homework === "" ? null : data.next_homework,
            section: watchsection === "all" ? null : watchsection,
            model_answer_visibilty: 1,
            visibility: +data?.visibility,
            img: data?.img[0] || null,
          }
        : {
            ...data,
            img: data?.img[0] || null,
          };
    dispatch({
      type: "setSubmitAddEditChapter",
      payload: {
        flag: "AddEditChapter",
        dependency: !state.submitAddEditChapter.dependency,
        data: finalData,
      },
    });
  };
  console.log(watchVisible);
  // useEffect(() => {
  //   if (watchType) {
  //     setValue("stage", "");
  //   }
  // }, [watchType, setValue]);

  if (ChapterInfoLoading) return <Loader />;
  if (type === "homework") {
    return (
      <section className="flex h-auto w-full flex-col  items-center  dark:bg-dark dark:text-light">
        <HelmetTags title="HomeWork | Ms Amira Shaltout"></HelmetTags>

        <div className="form-container my-20 flex w-full flex-col items-center">
          <h2 className="my-20 text-center text-3xl font-semibold">
            {chapterID
              ? "Edit Homework"
              : "Please fill in the information to add the Homework"}
          </h2>

          <form
            encType="multipart/form-data"
            method="post"
            onSubmit={handleSubmit(onSubmit)}
            className="md:max-w-3/4 flex  w-3/4  flex-col items-start justify-center gap-10 rounded-3xl bg-white p-16 shadow-3xl"
          >
            <div className="flex w-full items-start justify-between gap-16 md:flex-col md:gap-10 ">
              <div className="name flex w-full flex-col items-start justify-center  gap-2  md:w-full">
                <label htmlFor="name">Name</label>
                <input
                  className="signin-inputs w-full pl-4    "
                  type="text"
                  id="name"
                  placeholder="Name"
                  name="name"
                  autoComplete="on"
                  {...register("name", {
                    required: true,
                    maxLength: 40,
                    minLength: 3,
                  })}
                />
                {errors.name && (
                  <p className="w-full  text-[12px] text-blue-900  ">
                    {errors.name.type === "required" &&
                      "Please fill out this field"}
                    {errors.name.type === "maxLength" &&
                      "The maximum number of characters is 40 characters"}
                    {errors.name.type === "minLength" &&
                      "Please write at least 3 characters"}
                  </p>
                )}

                {
                  //!-------server errors -----

                  ChapterAddEditErrors &&
                    ChapterAddEditErrors?.response?.data?.errors?.name && (
                      <p className="w-full  text-[12px] text-blue-900  ">
                        {ChapterAddEditErrors?.response?.data?.errors?.name[0]}
                      </p>
                    )
                }
              </div>
              <div className="flex w-1/2 flex-col items-start justify-center gap-2  md:w-full">
                <label htmlFor="section">Section</label>
                <select
                  name="section"
                  id="section"
                  placeholder="Select Section"
                  value={watchsection || "all"} // Display "all" if section is null
                  onChange={handleSectionChange} // Custom handler to map "all" to null
                  {...register("section")}
                >
                  <option value="est">EST</option>
                  <option value="act">ACT</option>
                  <option value="dsat">DIGITAL SAT</option>
                  <option value="all">All</option>
                </select>

                {errors.section && (
                  <p className="text-xs text-red-500 ">
                    {errors.section === "required" && "This field is required."}
                  </p>
                )}
                {
                  //!-------server errors -----

                  errorHomeWork &&
                    errorHomeWork?.response?.data?.errors?.section && (
                      <p className="w-full text-end text-xs text-red-500  ">
                        {errorHomeWork?.response?.data?.errors?.section[0]}
                      </p>
                    )
                }
              </div>
            </div>
            {data?.length !== 0 ? (
              <div className="flex flex-col items-start gap-5 w-full">
                <label htmlFor="linked">Linked Homework</label>
                <select
                  name="linked"
                  id="linked"
                  placeholder="Select Practice"
                  {...register("next_homework")}
                >
                  <option value={""}>No Link</option>

                  {data?.map((e) => (
                    <>
                      <option value={e.key}>{e.name}</option>
                    </>
                  ))}
                </select>

                {errors.next_homework && (
                  <p className="text-xs text-red-500 ">
                    {errors.next_homework === "required" &&
                      "This field is required."}
                  </p>
                )}
                {
                  //!-------server errors -----

                  errorHomeWork &&
                    errorHomeWork?.response?.data?.errors?.next_homework && (
                      <p className="w-full text-end text-xs text-red-500  ">
                        {
                          errorHomeWork?.response?.data?.errors
                            ?.next_homework[0]
                        }
                      </p>
                    )
                }
              </div>
            ) : null}
            {/** Homework Image  */}
            <div className="flex w-full flex-col items-end gap-2">
              <label className="w-full" htmlFor="img">
                Upload Homework image
              </label>

              <input
                id="img"
                className="signin-inputs   w-full"
                type="file"
                accept="image/jpg, image/jpeg, image/png, image/webp"
                name="img"
                {...register("img", {
                  required: values ? false : true,
                  validate: (value) => !(value[0]?.size > 50000000),
                })}
              />

              {errors.img && (
                <p className="mt-2 w-full  text-[12px] text-blue-900">
                  {errors.img.type === "required" &&
                    "Please add a picture of the class"}
                  {errors.img.type === "validate" &&
                    "Maximum image size is 50MB"}
                </p>
              )}
              {
                //!-------server errors -----

                ChapterAddEditErrors &&
                  ChapterAddEditErrors?.response?.data?.errors?.img && (
                    <p className="w-full  text-[12px] text-blue-900  ">
                      {ChapterAddEditErrors?.response?.data?.errors?.img[0]}
                    </p>
                  )
              }
            </div>

            {/* 
          <div className="flex w-full items-start justify-between gap-16 md:flex-col md:gap-10 ">
            <div className="flex w-full items-start justify-between gap-16 md:flex-col md:gap-10 ">
              <div className="flex w-1/2 flex-col  items-center md:order-2 md:w-full">
                <label
                  className="text-start flex items-center gap-2"
                  htmlFor="Type"
                >
                  Type
                </label>
                <div className="flex w-full gap-20 pb-1 sm:flex-col-reverse">
                  <div className=" flex  items-center justify-center gap-2 ">
                    <input
                      className="cursor-pointer"
                      type="radio"
                      id="ig"
                      value="0"
                      {...register("edu_type", { required: true })}
                    />
                    <label className="cursor-pointer text-start" htmlFor="ig">
                      IG
                    </label>
                  </div>

                  <div className=" flex  items-center justify-center gap-2 ">
                    <input
                      className="cursor-pointer"
                      type="radio"
                      id="national"
                      value="1"
                      {...register("edu_type", { required: true })}
                    />
                    <label
                      className="cursor-pointer text-start"
                      htmlFor="national"
                    >
                      National
                    </label>
                  </div>
                </div>
                {errors.section && (
                  <p className="w-full text-start text-[12px] text-blue-900  ">
                    {errors.section.edu_type === "required" &&
                      "Please fill out this field"}
                  </p>
                )}
                {
                  //!-------server errors -----

                  state.errors && state.errors.edu_type && (
                    <p className="w-full text-start text-[12px] text-blue-900  ">
                      {state.errors.edu_type[0]}
                    </p>
                  )
                }
                {
                  //!-------Editing server errors -----

                  ChapterAddEditErrors?.response?.data?.errors &&
                    ChapterAddEditErrors?.response?.data?.errors?.edu_type && (
                      <p className="w-full text-start text-[12px] text-blue-900  ">
                        {
                          ChapterAddEditErrors?.response?.data.errors
                            .edu_type[0]
                        }
                      </p>
                    )
                }
              </div>
               {!ChapterInfoLoading && (
                <>
                  {watchType !== null && (
                    <div
                      className={`flex w-1/2 flex-col items-end justify-center gap-2 md:order-2  md:w-full `}
                    >
                      <label className="w-full truncate" htmlFor="stage">
                        Classroom
                      </label>

                      <select
                        name="stage"
                        id="stage"
                        {...register("stage", { required: true })}
                      >
                        <option value="" disabled selected>
                          Select a stage
                        </option>
                        {watchType === "0" ? (
                          <>
                            <option value="9">Nine Year</option>
                            <option value="10">Ten Year</option>
                          </>
                        ) : (
                          <>
                            <option value="22">Second preparatory</option>
                            <option value="33">Third preparatory </option>
                            <option value="1">First Secondary</option>
                            <option value="2">Second Secondary</option>
                            <option value="3">Third Secondary</option>
                          </>
                        )}
                      </select>

                      {errors.stage && (
                        <p className="text-[12px] text-blue-900 ">
                          {errors.stage.type === "required" &&
                            "Please fill out this field"}
                        </p>
                      )}
                      {
                        //!-------server errors -----

                        ChapterAddEditErrors &&
                          ChapterAddEditErrors?.response?.data?.errors
                            ?.stage && (
                            <p className="w-full  text-[12px] text-blue-900  ">
                              {
                                ChapterAddEditErrors?.response?.data?.errors
                                  ?.stage[0]
                              }
                            </p>
                          )
                      }
                    </div>
                  )}
                </>
              )} 
            </div>
          </div> */}

            {/* {watchType === "1" && (
            <>
              {" "}
              {+watchLevel === 2 || +watchLevel === 3 ? (
                <>
                  <div className="flex w-full items-end gap-20 pb-1 sm:flex-col-reverse">

                    <div className=" flex  items-center justify-center gap-2 ">
                      <input
                        className="cursor-pointer"
                        type="radio"
                        id="scientific"
                        value="scientific"
                        {...register("section", { required: true })}
                      />
                      <label
                        className="cursor-pointer text-start"
                        htmlFor="scientific"
                      >
                        Scientific
                      </label>
                    </div>

                    <div className=" flex  items-center justify-center gap-2 ">
                      <input
                        className="cursor-pointer"
                        type="radio"
                        id="arts"
                        value="arts"
                        {...register("section", { required: true })}
                      />
                      <label
                        className="cursor-pointer text-start"
                        htmlFor="arts"
                      >
                        Arts
                      </label>
                    </div>
                  </div>
                  {errors.section && (
                    <p className="w-full text-start text-[12px] text-blue-900  ">
                      {errors.section.type === "required" &&
                        "Please fill out this field"}
                    </p>
                  )}
                  {
                    //!-------server errors -----

                    state.errors && state.errors.section && (
                      <p className="w-full text-start text-[12px] text-blue-900  ">
                        {state.errors.section[0]}
                      </p>
                    )
                  }
                  {
                    //!-------Editing server errors -----

                    ChapterAddEditErrors?.response?.data?.errors &&
                      ChapterAddEditErrors?.response?.data?.errors?.section && (
                        <p className="w-full text-start text-[12px] text-blue-900  ">
                          {
                            ChapterAddEditErrors?.response?.data.errors
                              .section[0]
                          }
                        </p>
                      )
                  }
                </>
              ) : (
                ""
              )}
            </>
          )} */}
            {/*   <div
              className={`flex w-full flex-col items-end justify-center gap-2   md:w-full `}
            >
              {data?.length !== 0 ? (
                <>
                  <label className="w-full truncate" htmlFor="homework">
                    Linked Homeworks
                  </label>
                  <select
                    name="homework"
                    id="homework"
                    defaultValue={ChapterInfo?.data?.next_homework || ""}
                    {...register("next_homework")}
                  >
                    <option value="">No Link</option>
                    {data?.map((homework) => (
                      <option key={homework.key} value={homework.key}>
                        {homework.name}
                      </option>
                    ))}
                  </select>
                </>
              ) : null}

              {errors.next_homework && (
                <p className="text-[12px] text-blue-900 ">
                  {errors.next_homework.type === "required" &&
                    "Please fill out this field"}
                </p>
              )}
              {
                //!-------server errors -----

                ChapterAddEditErrors &&
                  ChapterAddEditErrors?.response?.data?.errors?.section && (
                    <p className="w-full  text-[12px] text-blue-900  ">
                      {ChapterAddEditErrors?.response?.data?.errors?.section[0]}
                    </p>
                  )
              }
            </div> */}
            {type === "homework" && (
              <div className="flex  w-full items-start justify-end gap-16 md:flex-col md:gap-10 ">
                <div className=" visibility flex w-full flex-col items-end justify-center gap-2 md:w-full">
                  <div className="flex w-full items-center justify-end gap-10">
                    <label className="visibility-switch">
                      <input
                        className=""
                        id="visibility"
                        name="visibility"
                        {...register("visibility", {
                          required: false,
                        })}
                        type="checkbox"
                      />
                      <span className="visibility-slider">
                        <svg
                          className="slider-icon"
                          viewBox="0 0 32 32"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                          role="presentation"
                        >
                          <path fill="none" d="m4 16.5 8 8 16-16"></path>
                        </svg>
                      </span>
                    </label>
                    <div>
                      <label
                        className="w-full cursor-pointer truncate"
                        htmlFor="visibility"
                      >
                        Visibility
                      </label>
                    </div>
                  </div>

                  {errors.visibility && (
                    <p className="text-[12px] text-white ">
                      {errors.visibility.type === "required" &&
                        "برجاء ملئ هذا الحقل"}{" "}
                    </p>
                  )}
                  {
                    //!-------server errors -----

                    ChapterAddEditErrors &&
                      ChapterAddEditErrors?.response?.data?.errors
                        ?.visibility && (
                        <p className="w-full text-end text-[12px] text-white  ">
                          {
                            ChapterAddEditErrors?.response?.data?.errors
                              ?.visibility[0]
                          }
                        </p>
                      )
                  }
                </div>
              </div>
            )}
            <div className=" flex w-full flex-col items-end justify-center gap-2 ">
              <label className="w-full truncate " htmlFor="duration">
                Start Deadline
              </label>
              <input
                ref={datestart}
                type="datetime-local"
                className=" signin-inputs"
                {...register("start_deadline", {
                  required: true,
                })}
              />
              {errors.start_deadline && (
                <p className="text-[12px] text-blue-900 ">
                  {errors.start_deadline.type === "maxLength" &&
                    "The maximum number of characters is 155 characters"}
                  {errors.start_deadline.type === "minLength" &&
                    "Please write at least 3 characters"}
                </p>
              )}
              {
                //!-------server errors -----

                ChapterAddEditErrors &&
                  ChapterAddEditErrors?.response?.data?.errors
                    ?.start_deadline && (
                    <p className="w-full  text-[12px] text-blue-900  ">
                      {
                        ChapterAddEditErrors?.response?.data?.errors
                          ?.start_deadline[0]
                      }
                    </p>
                  )
              }
            </div>
            <div className=" flex w-full flex-col items-end justify-center gap-2 ">
              <label className="w-full truncate " htmlFor="duration">
                End Deadline
              </label>
              <input
                ref={dateend}
                type="datetime-local"
                className=" signin-inputs"
                {...register("end_deadline", {
                  required: true,
                })}
              />
              {errors.start_deadline && (
                <p className="text-[12px] text-blue-900 ">
                  {errors.start_deadline.type === "maxLength" &&
                    "The maximum number of characters is 155 characters"}
                  {errors.start_deadline.type === "minLength" &&
                    "Please write at least 3 characters"}
                </p>
              )}
              {
                //!-------server errors -----

                ChapterAddEditErrors &&
                  ChapterAddEditErrors?.response?.data?.errors
                    ?.start_deadline && (
                    <p className="w-full  text-[12px] text-blue-900  ">
                      {
                        ChapterAddEditErrors?.response?.data?.errors
                          ?.start_deadline[0]
                      }
                    </p>
                  )
              }
            </div>
            <div className=" flex w-full flex-col items-end justify-center gap-2 ">
              <label className="w-full truncate " htmlFor="duration">
                Duration
              </label>
              <input
                type="number"
                className=" signin-inputs"
                {...register("duration", {
                  required: true,
                })}
              />
              {errors.duration && (
                <p className="text-[12px] text-blue-900 ">
                  {errors.duration.type === "maxLength" &&
                    "The maximum number of characters is 155 characters"}
                  {errors.duration.type === "minLength" &&
                    "Please write at least 3 characters"}
                </p>
              )}
              {
                //!-------server errors -----

                ChapterAddEditErrors &&
                  ChapterAddEditErrors?.response?.data?.errors?.duration && (
                    <p className="w-full  text-[12px] text-blue-900  ">
                      {
                        ChapterAddEditErrors?.response?.data?.errors
                          ?.duration[0]
                      }
                    </p>
                  )
              }
            </div>
            <div className=" flex w-full flex-col items-end justify-center gap-2 ">
              <label className="w-full truncate " htmlFor="description">
                Description
              </label>
              <textarea
                className=" resize-none"
                {...register("description", {
                  maxLength: 155,
                  minLength: 3,
                })}
                name="description"
                id="description"
                cols="40"
                rows="5"
              ></textarea>
              {errors.description && (
                <p className="text-[12px] text-blue-900 ">
                  {errors.description.type === "maxLength" &&
                    "The maximum number of characters is 155 characters"}
                  {errors.description.type === "minLength" &&
                    "Please write at least 3 characters"}
                </p>
              )}
              {
                //!-------server errors -----

                ChapterAddEditErrors &&
                  ChapterAddEditErrors?.response?.data?.errors?.description && (
                    <p className="w-full  text-[12px] text-blue-900  ">
                      {
                        ChapterAddEditErrors?.response?.data?.errors
                          ?.description[0]
                      }
                    </p>
                  )
              }
            </div>

            {/** submit */}

            <button
              disabled={
                !isValid ||
                (ChapterAddEditLoading && state.submitAddEditChapter.flag)
              }
              className=" submit mt-6 w-full "
              type="submit"
            >
              {ChapterAddEditLoading && state.submitAddEditChapter.flag ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                <p> {chapterID ? "Edit Homework" : "Add Homework"}</p>
              )}
            </button>
          </form>
        </div>
      </section>
    );
  }
  return (
    <section className="flex h-auto w-full flex-col  items-center  dark:bg-dark dark:text-light">
      <HelmetTags title="Courses | Ms Amira Shaltout"></HelmetTags>

      <div className="form-container my-20 flex w-full flex-col items-center">
        <h2 className="my-20 text-center text-3xl font-semibold">
          {chapterID
            ? "Edit Course"
            : "Please fill in the information to add the Course"}
        </h2>

        <form
          encType="multipart/form-data"
          method="post"
          onSubmit={handleSubmit(onSubmit)}
          className="md:max-w-3/4 flex  w-3/4  flex-col items-start justify-center gap-10 rounded-3xl bg-white p-16 shadow-3xl"
        >
          <div className="flex w-full items-start justify-between gap-16 md:flex-col md:gap-10 ">
            <div className="name flex w-full flex-col items-start justify-center  gap-2  md:w-full">
              <label htmlFor="name">Name</label>
              <input
                className="signin-inputs w-full pl-4    "
                type="text"
                id="name"
                placeholder="Name"
                name="name"
                autoComplete="on"
                {...register("name", {
                  required: true,
                  maxLength: 40,
                  minLength: 3,
                })}
              />
              {errors.name && (
                <p className="w-full  text-[12px] text-blue-900  ">
                  {errors.name.type === "required" &&
                    "Please fill out this field"}
                  {errors.name.type === "maxLength" &&
                    "The maximum number of characters is 40 characters"}
                  {errors.name.type === "minLength" &&
                    "Please write at least 3 characters"}
                </p>
              )}

              {
                //!-------server errors -----

                ChapterAddEditErrors &&
                  ChapterAddEditErrors?.response?.data?.errors?.name && (
                    <p className="w-full  text-[12px] text-blue-900  ">
                      {ChapterAddEditErrors?.response?.data?.errors?.name[0]}
                    </p>
                  )
              }
            </div>
          </div>

          <div
            className={`flex w-full flex-col items-end justify-center gap-2 md:order-2  md:w-full `}
          >
            {+watchVisible === 0 ? (
              <>
                <label className="w-full truncate" htmlFor="stage">
                  Section
                </label>
                <select
                  name="section"
                  id="section"
                  {...register("section", { required: true })}
                >
                  <option value="" disabled selected>
                    Select a section
                  </option>

                  <>
                    <option value="est">EST</option>
                    <option value="act">ACT </option>
                    <option value="dsat">DIGITAL SAT</option>
                  </>
                </select>
              </>
            ) : null}

            {errors.section && (
              <p className="text-[12px] text-blue-900 ">
                {errors.section.type === "required" &&
                  "Please fill out this field"}
              </p>
            )}
            {
              //!-------server errors -----

              ChapterAddEditErrors &&
                ChapterAddEditErrors?.response?.data?.errors?.section && (
                  <p className="w-full  text-[12px] text-blue-900  ">
                    {ChapterAddEditErrors?.response?.data?.errors?.section[0]}
                  </p>
                )
            }
          </div>

          <div
            className={`flex w-full flex-wrap items-start  gap-5 md:order-2  md:w-full `}
          >
            <label className="w-full truncate" htmlFor="stage">
              To
            </label>
            <div className="flex gap-5 items-center">
              <input
                type="radio"
                name=""
                id=""
                value={0}
                {...register("visible_to_all")}
              />
              <p>To Specific</p>
            </div>
            <div className="flex gap-5 items-center">
              <input
                type="radio"
                name=""
                id=""
                value={1}
                {...register("visible_to_all")}
              />
              <p>To All</p>
            </div>

            {errors.visible_to_all && (
              <p className="text-[12px] text-blue-900 ">
                {errors.visible_to_all.type === "required" &&
                  "Please fill out this field"}
              </p>
            )}
            {
              //!-------server errors -----

              ChapterAddEditErrors &&
                ChapterAddEditErrors?.response?.data?.errors
                  ?.visible_to_all && (
                  <p className="w-full  text-[12px] text-blue-900  ">
                    {
                      ChapterAddEditErrors?.response?.data?.errors
                        ?.visible_to_all[0]
                    }
                  </p>
                )
            }
          </div>
          {/* 
          <div className="flex w-full items-start justify-between gap-16 md:flex-col md:gap-10 ">
            <div className="flex w-full items-start justify-between gap-16 md:flex-col md:gap-10 ">
              <div className="flex w-1/2 flex-col  items-center md:order-2 md:w-full">
                <label
                  className="text-start flex items-center gap-2"
                  htmlFor="Type"
                >
                  Type
                </label>
                <div className="flex w-full gap-20 pb-1 sm:flex-col-reverse">
                  <div className=" flex  items-center justify-center gap-2 ">
                    <input
                      className="cursor-pointer"
                      type="radio"
                      id="ig"
                      value="0"
                      {...register("edu_type", { required: true })}
                    />
                    <label className="cursor-pointer text-start" htmlFor="ig">
                      IG
                    </label>
                  </div>

                  <div className=" flex  items-center justify-center gap-2 ">
                    <input
                      className="cursor-pointer"
                      type="radio"
                      id="national"
                      value="1"
                      {...register("edu_type", { required: true })}
                    />
                    <label
                      className="cursor-pointer text-start"
                      htmlFor="national"
                    >
                      National
                    </label>
                  </div>
                </div>
                {errors.section && (
                  <p className="w-full text-start text-[12px] text-blue-900  ">
                    {errors.section.edu_type === "required" &&
                      "Please fill out this field"}
                  </p>
                )}
                {
                  //!-------server errors -----

                  state.errors && state.errors.edu_type && (
                    <p className="w-full text-start text-[12px] text-blue-900  ">
                      {state.errors.edu_type[0]}
                    </p>
                  )
                }
                {
                  //!-------Editing server errors -----

                  ChapterAddEditErrors?.response?.data?.errors &&
                    ChapterAddEditErrors?.response?.data?.errors?.edu_type && (
                      <p className="w-full text-start text-[12px] text-blue-900  ">
                        {
                          ChapterAddEditErrors?.response?.data.errors
                            .edu_type[0]
                        }
                      </p>
                    )
                }
              </div>
               {!ChapterInfoLoading && (
                <>
                  {watchType !== null && (
                    <div
                      className={`flex w-1/2 flex-col items-end justify-center gap-2 md:order-2  md:w-full `}
                    >
                      <label className="w-full truncate" htmlFor="stage">
                        Classroom
                      </label>

                      <select
                        name="stage"
                        id="stage"
                        {...register("stage", { required: true })}
                      >
                        <option value="" disabled selected>
                          Select a stage
                        </option>
                        {watchType === "0" ? (
                          <>
                            <option value="9">Nine Year</option>
                            <option value="10">Ten Year</option>
                          </>
                        ) : (
                          <>
                            <option value="22">Second preparatory</option>
                            <option value="33">Third preparatory </option>
                            <option value="1">First Secondary</option>
                            <option value="2">Second Secondary</option>
                            <option value="3">Third Secondary</option>
                          </>
                        )}
                      </select>

                      {errors.stage && (
                        <p className="text-[12px] text-blue-900 ">
                          {errors.stage.type === "required" &&
                            "Please fill out this field"}
                        </p>
                      )}
                      {
                        //!-------server errors -----

                        ChapterAddEditErrors &&
                          ChapterAddEditErrors?.response?.data?.errors
                            ?.stage && (
                            <p className="w-full  text-[12px] text-blue-900  ">
                              {
                                ChapterAddEditErrors?.response?.data?.errors
                                  ?.stage[0]
                              }
                            </p>
                          )
                      }
                    </div>
                  )}
                </>
              )} 
            </div>
          </div> */}

          {/* {watchType === "1" && (
            <>
              {" "}
              {+watchLevel === 2 || +watchLevel === 3 ? (
                <>
                  <div className="flex w-full items-end gap-20 pb-1 sm:flex-col-reverse">

                    <div className=" flex  items-center justify-center gap-2 ">
                      <input
                        className="cursor-pointer"
                        type="radio"
                        id="scientific"
                        value="scientific"
                        {...register("section", { required: true })}
                      />
                      <label
                        className="cursor-pointer text-start"
                        htmlFor="scientific"
                      >
                        Scientific
                      </label>
                    </div>

                    <div className=" flex  items-center justify-center gap-2 ">
                      <input
                        className="cursor-pointer"
                        type="radio"
                        id="arts"
                        value="arts"
                        {...register("section", { required: true })}
                      />
                      <label
                        className="cursor-pointer text-start"
                        htmlFor="arts"
                      >
                        Arts
                      </label>
                    </div>
                  </div>
                  {errors.section && (
                    <p className="w-full text-start text-[12px] text-blue-900  ">
                      {errors.section.type === "required" &&
                        "Please fill out this field"}
                    </p>
                  )}
                  {
                    //!-------server errors -----

                    state.errors && state.errors.section && (
                      <p className="w-full text-start text-[12px] text-blue-900  ">
                        {state.errors.section[0]}
                      </p>
                    )
                  }
                  {
                    //!-------Editing server errors -----

                    ChapterAddEditErrors?.response?.data?.errors &&
                      ChapterAddEditErrors?.response?.data?.errors?.section && (
                        <p className="w-full text-start text-[12px] text-blue-900  ">
                          {
                            ChapterAddEditErrors?.response?.data.errors
                              .section[0]
                          }
                        </p>
                      )
                  }
                </>
              ) : (
                ""
              )}
            </>
          )} */}

          {edit && (
            <div className="flex  w-full items-start justify-end gap-16 md:flex-col md:gap-10 ">
              <div className=" visibility flex w-full flex-col items-end justify-center gap-2 md:w-full">
                <div className="flex w-full items-center justify-end gap-10">
                  <label className="visibility-switch">
                    <input
                      className=""
                      id="visibility"
                      name="visibility"
                      {...register("visibility", {
                        required: false,
                      })}
                      type="checkbox"
                    />
                    <span className="visibility-slider">
                      <svg
                        className="slider-icon"
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        role="presentation"
                      >
                        <path fill="none" d="m4 16.5 8 8 16-16"></path>
                      </svg>
                    </span>
                  </label>
                  <div>
                    <label
                      className="w-full cursor-pointer truncate"
                      htmlFor="visibility"
                    >
                      Visibility
                    </label>
                  </div>
                </div>

                {errors.visibility && (
                  <p className="text-[12px] text-white ">
                    {errors.visibility.type === "required" &&
                      "برجاء ملئ هذا الحقل"}{" "}
                  </p>
                )}
                {
                  //!-------server errors -----

                  ChapterAddEditErrors &&
                    ChapterAddEditErrors?.response?.data?.errors
                      ?.visibility && (
                      <p className="w-full text-end text-[12px] text-white  ">
                        {
                          ChapterAddEditErrors?.response?.data?.errors
                            ?.visibility[0]
                        }
                      </p>
                    )
                }
              </div>
            </div>
          )}

          {/** course Image  */}
          <div className="flex w-full flex-col items-end gap-2">
            <label className="w-full" htmlFor="img">
              Upload Course image
            </label>

            <input
              id="img"
              className="signin-inputs   w-full"
              type="file"
              accept="image/jpg, image/jpeg, image/png, image/webp"
              name="img"
              {...register("img", {
                required: values ? false : true,
                validate: (value) => !(value[0]?.size > 50000000),
              })}
            />

            {errors.img && (
              <p className="mt-2 w-full  text-[12px] text-blue-900">
                {errors.img.type === "required" &&
                  "Please add a picture of the class"}
                {errors.img.type === "validate" && "Maximum image size is 50MB"}
              </p>
            )}
            {
              //!-------server errors -----

              ChapterAddEditErrors &&
                ChapterAddEditErrors?.response?.data?.errors?.img && (
                  <p className="w-full  text-[12px] text-blue-900  ">
                    {ChapterAddEditErrors?.response?.data?.errors?.img[0]}
                  </p>
                )
            }
          </div>
          <div className=" flex w-full flex-col items-end justify-center gap-2 ">
            <label className="w-full truncate " htmlFor="description">
              Description
            </label>
            <textarea
              className=" resize-none"
              {...register("description", {
                maxLength: 155,
                minLength: 3,
              })}
              name="description"
              id="description"
              cols="40"
              rows="5"
            ></textarea>
            {errors.description && (
              <p className="text-[12px] text-blue-900 ">
                {errors.description.type === "maxLength" &&
                  "The maximum number of characters is 155 characters"}
                {errors.description.type === "minLength" &&
                  "Please write at least 3 characters"}
              </p>
            )}
            {
              //!-------server errors -----

              ChapterAddEditErrors &&
                ChapterAddEditErrors?.response?.data?.errors?.description && (
                  <p className="w-full  text-[12px] text-blue-900  ">
                    {
                      ChapterAddEditErrors?.response?.data?.errors
                        ?.description[0]
                    }
                  </p>
                )
            }
          </div>
          {/** submit */}

          <button
            disabled={
              !isValid ||
              (ChapterAddEditLoading && state.submitAddEditChapter.flag)
            }
            className=" submit mt-6 w-full "
            type="submit"
          >
            {ChapterAddEditLoading && state.submitAddEditChapter.flag ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              <p> {chapterID ? "Edit Course" : "Add Course"}</p>
            )}
          </button>
        </form>
      </div>
    </section>
  );
}

export default AddEditChapter;
