import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { Loader } from "../../../../MainComponents";
import SingleChapter from "../../../../MainComponents/SingleChapter";
import { useState } from "react";
import HelmetTags from "../../../../MainComponents/HelmetTags";

export default function AdminHomework() {
  const [reFetch, setReFtech] = useState(false);
  const [academicSection, setAcademicSection] = useState("all");
  const [getHomeWork, errorHomeWork, LoadingHomework] = useAxios(
    process.env.REACT_APP_GET_HOMEWORK_API,
    "GET",
    "GET",
    reFetch
  );
  const filteredHomeWork = getHomeWork?.data?.filter((lecture) => {
    if (academicSection === "all") return true;

    if (academicSection === "visibletoall")
      return lecture.model_answer_visibilty === 1;
    return lecture.section === academicSection; // Filter by stage
  });
  if (LoadingHomework) return <Loader />;
  return (
    <section className="px-10 py-10">
      <HelmetTags title="HomeWork | Ms.Amira shaltout "></HelmetTags>
      <div className="flex w-full gap-10 md:justify-evenly">
        <Link
          to={"/admin/homework/add-chapter"}
          className="mr-4 rounded-xl border-2 border-secondary order-1 bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-light hover:text-secondary active:scale-90"
        >
          <FontAwesomeIcon className="mr-4" icon={faPlus} />
          Add HomeWork
        </Link>
      </div>
      <div className="flex w-full flex-wrap py-10 gap-10 md:justify-center sm:flex-col-reverse sm:items-center">
        <button
          onClick={() => {
            setAcademicSection("all");
          }}
          className={`min-w-[200px] rounded-xl border-2 border-secondary p-4 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
            academicSection === "all" && "bg-secondary text-light"
          }`}
        >
          All Homework
        </button>
        <button
          onClick={() => {
            setAcademicSection("est");
          }}
          className={`min-w-[200px] rounded-xl border-2 border-secondary p-4 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
            academicSection === "est" && "bg-secondary text-light"
          }`}
        >
          EST
        </button>

        <button
          onClick={() => {
            setAcademicSection("act");
          }}
          className={`min-w-[200px] rounded-xl border-2 border-secondary p-4 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
            academicSection === "act" && "bg-secondary text-light"
          }`}
        >
          ACT
        </button>
        <button
          onClick={() => {
            setAcademicSection("dsat");
          }}
          className={`min-w-[200px] rounded-xl border-2 border-secondary p-4 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
            academicSection === "dsat" && "bg-secondary text-light"
          }`}
        >
          DIGITAL SAT
        </button>
        <button
          onClick={() => {
            setAcademicSection("visibletoall");
          }}
          className={`min-w-[200px] rounded-xl border-2 border-secondary p-4 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
            academicSection === "visibletoall" && "bg-secondary text-light"
          }`}
        >
          Visible To All
        </button>
      </div>

      {LoadingHomework ? (
        <Loader />
      ) : (
        <div className="py-10 lectures grid-auto-fit">
          {getHomeWork?.data?.length === 0 ? (
            <p>No Homework</p>
          ) : (
            filteredHomeWork.map((Card) => (
              <SingleChapter
                type={"homework"}
                admin
                setReFetch={setReFtech}
                reFetch={reFetch}
                chapter={Card}
                key={Card.key}
              />
            ))
          )}
        </div>
      )}
    </section>
  );
}
