import { useEffect, useState } from "react";
import SingleCourse from "../../../../MainComponents/SingleCourse";
import SingleChapter from "../../../../MainComponents/SingleChapter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useAuthState } from "../../../../MainComponents/GlobalContext.jsx";
import useAxios from "../../../../MainComponents/Hooks/useAxios.jsx";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { Loader } from "../../../../MainComponents";
import { Input } from "antd";

function AdminLectures() {
  const navigate = useNavigate();
  const AuthState = useAuthState();
  const [reFetch, setReFetch] = useState(false);
  const [academicSection, setAcademicSection] = useState("all");

  const [allLecturesData, allLecturesErrors, allLecturesloading] = useAxios(
    process.env.REACT_APP_ADMIN_COURSES_TAB_API,
    "GET",
    "GET",
    reFetch
  );

  const filteredLectures = allLecturesData?.data?.filter((lecture) => {
    if (academicSection === "all") return true; // Show all lectures
    if (academicSection === "visibletoall") return lecture.visible_to_all === 1;
    return lecture.section === academicSection; // Filter by stage
  });

  return (
    <section
      className={`h-full  w-full  px-20 py-20  dark:bg-dark dark:text-light `}
    >
      <HelmetTags title="Courses | Ms Amira Shaltout"></HelmetTags>
      <div className="mb-32 flex flex-col items-end gap-20">
        <h2 className="w-full text-4xl font-bold md:text-center">Courses</h2>
        {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
          AuthState.userData?.admin?.permissions?.some(
            (permission) => permission.name === "lecture_add"
          )) && (
            <div className="flex w-full gap-10 md:justify-evenly">
              <button
                onClick={() => navigate("/admin/lectures/add-chapter")}
                className="mr-4 rounded-xl border-2 border-secondary order-1 bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-light hover:text-secondary active:scale-90"
              >
                <FontAwesomeIcon className="mr-4" icon={faPlus} />
                Add Course
              </button>
            </div>
          )}
        <div className="flex w-full flex-wrap  gap-10 md:justify-center sm:flex-col-reverse sm:items-center">
          <button
            onClick={() => {
              setAcademicSection("all");
            }}
            className={`min-w-[200px] rounded-xl border-2 border-secondary p-4 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${academicSection === "all" && "bg-secondary text-light"
              }`}
          >
            All
          </button>
          <button
            onClick={() => {
              setAcademicSection("est");
            }}
            className={`min-w-[200px] rounded-xl border-2 border-secondary p-4 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${academicSection === "est" && "bg-secondary text-light"
              }`}
          >
            EST
          </button>

          <button
            onClick={() => {
              setAcademicSection("act");
            }}
            className={`min-w-[200px] rounded-xl border-2 border-secondary p-4 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${academicSection === "act" && "bg-secondary text-light"
              }`}
          >
            ACT
          </button>
          <button
            onClick={() => {
              setAcademicSection("visibletoall");
            }}
            className={`min-w-[200px] rounded-xl border-2 border-secondary p-4 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${academicSection === "visibletoall" && "bg-secondary text-light"
              }`}
          >
            Visible To All
          </button>
        </div>
      </div>

      {allLecturesloading ? (
        <Loader />
      ) : (
        <div className="lectures grid-auto-fit">
          {filteredLectures?.length === 0 ? (
            <p>No Courses</p>
          ) : (
            filteredLectures?.map((Card) => (
              <SingleChapter
                reFetch={reFetch}
                setReFetch={setReFetch}
                admin
                chapter={Card}
                key={Card.key}
              />
            ))
          )}
        </div>
      )}
    </section>
  );
}

export default AdminLectures;
