import { useEffect, useMemo, useReducer } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import useAxios from "../../../MainComponents/Hooks/useAxios";
import { toast } from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

import QuizHeader from "./component/quizHeader/QuizHeader";
import { DisplayQuestion } from "./component/displayQuestion/DisplayQuestion";
import Question from "./component/displayQuestion/Question";
import QuizFooter from "./component/quizFooter/QuizFooter";
import RightQuestionsSidebar from "./component/quizHeader/RightQuestionsSidebar";
import DisplayAnswers from "./component/displayAnswers/DisplayAnswers";
import QuestionsAnswers from "./component/displayAnswers/QuestionsAnswers";
import PercentageSidebar from "./component/displayAnswers/PercentageSidebar";
import HelmetTags from "../../../MainComponents/HelmetTags";
import { Loader } from "../../../MainComponents";

function reducer(state, action) {
  switch (action.type) {
    case "setLoading": {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case "setCollapse": {
      return {
        ...state,
        collapse: !state.collapse,
      };
    }

    case "setQuestions": {
      return {
        ...state,
        questions: action.payload,
      };
    }

    case "setQuizInfo": {
      return {
        ...state,
        quizInfo: action.payload,
      };
    }

    case "setCurrentSectionIndex": {
      return {
        ...state,
        collapse: false,
        currentSectionIndex: action.payload,
      };
    }

    case "setIsShowAnswers": {
      return {
        ...state,
        isShowAnswers: true,
      };
    }

    case "setIsSelectedAnswer": {
      return {
        ...state,
        questions: state.questions.map((question) =>
          question.id === action.payload.questionId
            ? {
              ...question,
              selected_answer: action.payload.answer,
              is_selected: true,
              n: action.payload.n,
            }
            : question
        ),
      };
    }

    case "setAnsweredQuestions": {
      // Check if answer already exists
      const answerExists = state.answeredQuestions.some(
        (answer) => answer.id === action.payload.id
      );

      if (!answerExists) {
        return {
          ...state,
          answeredQuestions: [...state.answeredQuestions, action.payload],
        };
      } else {
        return {
          ...state,
          answeredQuestions: state.answeredQuestions.map((question) =>
            question.id === action.payload.id
              ? {
                ...question,
                order: action.payload.order,
                id: action.payload.id,
                n: action.payload.n,
              }
              : question
          ),
        };
      }
    }

    case "setFinishQuizData": {
      return {
        ...state,
        finishQuizData: action.payload,
      };
    }

    case "setAllData": {
      return {
        ...initialState,
      };
    }
    case "setRefetch": {
      return {
        ...state,
        refetch: action.payload,
      };
    }

    default:
      throw Error("Unknown action: " + action.type);
  }
}

const initialState = {
  loading: true,
  currentSectionIndex: 1,
  collapse: false,
  questions: [],
  quizInfo: {},
  answeredQuestions: [],
  finishQuizData: {},
  numOfSelected: 0,
  isShowAnswers: false,
  refetch: false,
};

const QuizNew = ({ homework, exam, type }) => {
  const { ID } = useParams();

  const [state, dispatch] = useReducer(reducer, initialState);

  const checkApi = homework
    ? process.env.REACT_APP_STUDENT_HOMEWORK_API
    : exam && type === "homeworkstudent"
      ? "https://api.amirashaltout.com/api/homework/questions"
      : exam
        ? "https://api.amirashaltout.com/api/trialexam/questions"
        : process.env.REACT_APP_STUDENT_QUIZZ_API;

  const checkFinishApi = homework
    ? process.env.REACT_APP_STUDENT_HOMEWORK_SUBMIT_API
    : exam && type === "homeworkstudent"
      ? "https://api.amirashaltout.com/api/homework/submit"
      : exam
        ? "https://api.amirashaltout.com/api/trialexam/submit"
        : process.env.REACT_APP_STUDENT_QUIZZ_SUBMIT_API;

  const [quizData, quizErrors, quizLoading] = useAxios(
    `${checkApi}/${ID}`,
    "GET",
    "GET",
    state.refetch,
    ""
  );
  const filteredParagraph = quizData?.paragraph?.filter(
    (item) => item.n_section === state.currentSectionIndex
  );
  useEffect(() => {
    if (quizData) {
      dispatch({
        type: "setQuestions",
        payload: [
          ...(quizData.choosequestions || []),
          ...(quizData.essayquestions || []),
        ],
      });
      dispatch({
        type: "setQuizInfo",
        payload: quizData.quiz || {},
      });
      dispatch({ type: "setRefetch", payload: false });
    }
  }, [quizData]);

  useEffect(() => {
    const selectedQuestions = state.questions.filter((q) => q.selected_answer);
    selectedQuestions.forEach((question) => {
      dispatch({
        type: "setAnsweredQuestions",
        payload: {
          id: question.id,
          order: question.selected_answer || "E",
          n: question.n,
        },
      });
    });
  }, [state.questions]);

  useMemo(() => {
    // set axios
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(localStorage.userData).student_token;
  }, []);

  const onFinishQuiz = () => {
    async function fetchData() {
      try {
        let res = await axios.post(checkFinishApi, {
          homework_id: type === "homeworkstudent" ? +ID : null,
          lecture_id: type === "homeworkstudent" ? null : +ID,
          answers: state.answeredQuestions,
        });
        dispatch({ type: "setFinishQuizData", payload: res.data.data });
        dispatch({ type: "setIsShowAnswers" });
      } catch (error) {
        toast.error(error.response.data.message);
      }
    }
    fetchData();
  };
  if (quizLoading) return <Loader />;
  if (
    quizData?.choosequestions.length === 0 &&
    quizData?.essayquestions.length === 0
  )
    return (
      <div className="h-screen w-full flex items-center justify-center">
        No Questions
      </div>
    );
  return (
    <section
      className={`flex h-fit  min-h-screen w-full flex-col items-center ${state.isShowAnswers ? "pt-[80px]" : "pt-[128px]"
        }`}
    >
      <HelmetTags
        title={`${homework ? "Homework" : "Homework"} | Ms Amira Shaltout `}
      ></HelmetTags>
      <QuizHeader
        state={state}
        appTimer={quizData?.quiz?.duration ? quizData?.quiz?.duration : quizData?.homework?.duration}
        dispatch={dispatch}
        onFinishQuiz={onFinishQuiz}
        quizErrors={quizErrors}
        quizLoading={quizLoading}
        exam={exam}
      />

      {quizErrors && (
        <div className="flex justify-center flex-col ">
          <p>{quizErrors?.response?.data.message}</p>
          <Link
            to={`${exam
                ? `/home/exams/sessions/solve-exam/${ID}`
                : `/lectures/sessions/${ID}`
              }`}
            className="flex justify-center my-6"
          >
            <div className="collapse-btn top-[96px] flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-full bg-accent text-light shadow-md shadow-black/40 duration-300 hover:scale-110 hover:text-light">
              <FontAwesomeIcon
                className={`transition-all duration-300 ease-in-out hover:scale-110 ${state.collapse && "rotate-180"
                  }`}
                icon={faChevronRight}
              />
            </div>
          </Link>
        </div>
      )}

      {quizLoading && <p>loading...</p>}

      {!quizLoading && !quizErrors && (
        <main
          className={`w-full min-h-fit md:min-h-fit flex-col md:gap-[40px] flex max-w-[1700px] justify-center items-center`}
        >
          {!state.isShowAnswers ? (
            <DisplayQuestion state={state}>
              {filteredParagraph?.length > 0 ? (
                <div className="flex gap-5 w-full">
                  <p
                    className="flex-1 bg-white rounded-2xl h-[90vh] overflow-y-auto"
                    dangerouslySetInnerHTML={{
                      __html: filteredParagraph[0]?.paragraph, // Assuming you display one paragraph at a time
                    }}
                  />
                  <Question
                    state={state}
                    dispatch={dispatch}
                    homework={homework}
                    exam={exam}
                    isParagraphe
                  />
                </div>
              ) : (
                <>
                  <Question
                    state={state}
                    dispatch={dispatch}
                    homework={homework}
                    exam={exam}
                  />
                </>
              )}
              <QuizFooter
                state={state}
                dispatch={dispatch}
                onFinishQuiz={onFinishQuiz}
              />
            </DisplayQuestion>
          ) : (
            <DisplayAnswers>
              <QuestionsAnswers
                state={state}
                dispatch={dispatch}
                exam={exam}
                type={type}
              />
              <PercentageSidebar state={state} />
            </DisplayAnswers>
          )}

          <RightQuestionsSidebar
            state={state}
            dispatch={dispatch}
            quizData={quizData}
          />
        </main>
      )}
    </section>
  );
};

export default QuizNew;
