import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import QuestionBankCategory from "../QuestionBank/QuestionBankCategory.jsx";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import Loader from "../../../../MainComponents/Loader.jsx";
import AddEditChooseQuestion from "../QuestionBank/AddEditChooseQuestion.jsx";
import AdminAddEditParagraphe from "./AdminAddEditParagraphe.jsx";

function AdminQuizSection({ homework, exam, type }) {
  const { examID, sectionNumber } = useParams();

  const [refetch, setRefetch] = useState(false);

  //!--------- get quiz / homework info for checking if there is a quiz or a homework has set previously  -------
  let InfoApi =
    type === "homework"
      ? "https://api.amirashaltout.com/api/admin/homework/info/section/"
      : type === "exam"
        ? "https://api.amirashaltout.com/api/admin/exam/info/section/"
        : `${process.env.REACT_APP_ADMIN_TRIAL_EXAM_GET_ALL_SECTION_INFO_API}`;

  const [getInfoSuccess, getInfoErrors, getInfoLoading] = useAxios(
    `${InfoApi}${examID}/${sectionNumber}`,
    "GET",
    "GET",
    refetch
  );

  if (getInfoLoading) {
    return <Loader />;
  }
  return (
    <section className="flex h-auto w-full flex-col items-start gap-20 overflow-y-auto px-20 py-20 dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags title={`Add Homework | Ms. Amira Shaltout`}></HelmetTags>

      {exam && (
        <AdminAddEditParagraphe
          type={type}
          getInfoSuccess={getInfoSuccess}
          refetch={refetch}
          setRefetch={setRefetch}
        />
      )}

      <div className="custom-question  w-full ">
        <h2 className=" text-3xl font-bold mb-6">Add a Question </h2>

        <AddEditChooseQuestion
          type={type}
          id={getInfoSuccess?.data?.quiz_info[0]?.key}
          refetch={refetch}
          setRefetch={setRefetch}
        />
      </div>
      {getInfoSuccess?.data?.Questions?.length > 0 && (
        <div className="all-custom-questions w-full ">
          <h2 className=" text-3xl font-bold mb-8"> All Questions </h2>
          <div className="flex gap-4">
            {getInfoSuccess?.data?.paragraph && (
              <div className=" flex-1  bg-white rounded-2xl h-[90vh] overflow-y-auto ">
                <div
                  className=" px-4"
                  dangerouslySetInnerHTML={{
                    __html: getInfoSuccess?.data?.paragraph,
                  }}
                />
              </div>
            )}

            <div className=" flex-1  bg-white rounded-2xl h-[90vh] overflow-y-auto">
              <QuestionBankCategory
                type={type === "homework" ? "homework" : "exam"}
                id={
                  homework
                    ? getInfoSuccess?.data?.homework_info[0].key
                    : getInfoSuccess?.data?.quiz_info[0]?.key
                }
                QuizQuestionsData={getInfoSuccess?.data?.Questions}
                setReFetch={setRefetch}
                reFetch={refetch}
              />
            </div>
          </div>
        </div>
      )}
    </section>
  );
}

export default AdminQuizSection;
