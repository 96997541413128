import { Doughnut } from "react-chartjs-2";

const PercentageSidebar = ({ state }) => {
  const percentageInfo = state.finishQuizData;
  const DoughnutData = {
    labels: ["Wrong Answers", "Correct Answers"],
    datasets: [
      {
        label: "Answers",
        data: [
          percentageInfo?.count_wrong,
          percentageInfo?.count_correct,
          percentageInfo?.essay_number,
        ],
        backgroundColor: ["#FF0000", "#84cc16"],
        borderColor: ["#FF0000", "#84cc16"],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        labels: {
          color: "white",
        },
      },
    },
  };

  return (
    <div
      className={`left-right-sidebar text- flex min-h-full w-1/4 flex-col   items-center  bg-yello px-[20px] py-[40px] md:-order-1 md:min-h-fit md:w-full`}
    >
      <p className="overall w-full text-white text-center text-[20px] font-bold">
        Sucsses percent
      </p>
      <div className="DoughnutData my-[36px] flex  w-full  flex-col items-center md:w-4/6 xs:w-5/6">
        <Doughnut data={DoughnutData} options={options} />
      </div>

      <p className="absolute-text mb-[20px] text-center text-[18px] font-bold  text-lime-500">
        Correct Answer <br />
        <span className="text-[18px]  font-normal">
          {percentageInfo?.percentage}%
        </span>
      </p>
      <p className="grade text-white">
        <span className="font-bold"> Degree:</span>
        {percentageInfo?.student_degree}/{percentageInfo?.degree}
      </p>
      {/* <p className="result mt-[8px] ">
        <span className="font-bold">النتيجة:</span>
        {percentageInfo.percentage * 100 < 50 ? " راسب" : " ناجح "}
      </p> */}
    </div>
  );
};

export default PercentageSidebar;
